<template lang="html">
<v-editable-pad v-model="registerData" title="เปลี่ยนพาสเวิร์ดและแก้ไขข้อมูล" v-slot="{data,rules,disabled}" @save="saveUser">
  <v-container fluid pa-0>
    <v-row>
      <v-col cols="12">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field :rules="[rules.require()]" v-model="data.name" label="ชื่อ-นามสกุล ผู้ใช้งาน"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="data.email" label="Email" v-permission="'transaction-add-discount'"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-master-field :rules="[rules.require()]" v-model="data.position" groupKey="$UserPosition" label="ตำแหน่ง"></v-master-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="data.licenseNo" label="เลขใบประกอบวิชาชีพ"></v-text-field>
            </v-col>
            <v-col cols="12">
              <span class="text-h6">หากไม่ต้องการเปลี่ยนพาสเวิร์ดให้เว้นว่าง</span>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field :rules="[rules.require(),rules.lengthGreater(6,'รหัสผ่านต้องความยาวอย่างน้อย 6 ตัวอักษร')]" v-model="data.password" label="รหัสผ่านใหม่" type="password"></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field :rules="[rules.require(),(v)=>{return v==data.password || 'รหัสผ่านไม่ตรงกัน'}]" v-model="data.password_confirmation" label="ยืนยันรหัสผ่านใหม่" type="password"></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</v-editable-pad>

</template>

<script>
import helperItem from '@/modules/helperItem'
import api from 'tantee-common/lib/api'

export default {
  data: ()=>({
    registerData: {},
    isSavingUser: undefined,
  }),
  methods: {
    saveUser(formData) {
      this.isSavingUser = true
      this.$confirm('ต้องการบันทึกแก้ไขข้อมูลผู้ใช้').then((res)=>{
        if (res) {
          api.post(['User','UserController','update'],formData).then((returnData)=> {
            if (returnData.success) {
              this.$notify('บันทึกแก้ไขข้อมูลเรียบร้อยแล้ว')
            } else {
              returnData.errorTexts.forEach((item)=>{
                this.$store.dispatch('addAlertItem',{
                  alertText: item.errorText,
                  alertType: 'error'
                })
              })
            }
          }).finally(()=>{
            this.isSavingUser = false
            this.loadUserData()
          })
        } else {
          this.isSavingUser = false
        }
      }).finally(()=>{
        this.isSavingUser = false
      })
    },
    loadUserData() {
      helperItem.firstItem(['models','User','Users'],{username: this.$store.getters.getCurrentUser.username}).then((returnData)=>{
        this.registerData = returnData
      }).catch((e)=>{
        void e
      })
    },
  },
  beforeMount() {
    this.loadUserData()
  },
}
</script>

<style lang="css" scoped>
</style>
